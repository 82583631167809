<template>
  <div>
    <user-list-add-new :key="isAddNewUserSidebarActive" :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions" :plan-options="planOptions" :course-id="courseId" :course-name="coursename"
      @refetch-data="refetchData" />

    <b-card no-body class="mb-0">

      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary" @click="addNewParticipantConfirm()">
                <span class="text-nowrap">{{ $t('Add Participants') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" :items="items" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc">

        <template #head()="data">
          <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDate(data.item.createdAt) }}</span>
          </div>
        </template>

        <template #cell(membername)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.memberphoto" :text="avatarText(data.item.membername)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`" />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ data.item.membername }}
            </b-link>
            <small class="text-muted">{{ data.item.membermail }}</small>

          </b-media>
        </template>

        <template #cell(detail)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.coursename }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button variant="danger" @click="removeSubscriberFromCourse({
            clientMemberGuid: data.item.clientmemberguid, courseGuid: data.item.courseguid
          })">{{ $t('Remove')
            }}</b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './ParticipantsListFilters.vue'
import useUsersListByCourse from './useParticipantsListByCourse'
import userStoreModule from '../participantStoreModule'
import UserListAddNew from './ParticipantListAddNew.vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {

  computed: {

  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
      return formattedDate.replace(',', '');
    },
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  watch: {


  },
  setup() {
    const { t } = useI18nUtils()
    const USER_APP_STORE_MODULE_NAME = 'apps-participants'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    watch(() => router.currentRoute.params.id, () => {
      fetchCourseSubscribers(search);
    });
    const initialCourse = router.currentRoute.params.id;
    const { coursename } = router.currentRoute.params;
    const YES = t('YES');
    const NO = t('NO');
    const warningTitle= t('Warning')
    const warningText = t('Your exam or certificate appears to be incomplete. Do you still want to continue?');
    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,
      hasCert,
      hasExam,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      fetchCourseSubscribers,
      removeSubscriberFromCourse,
      courseId,
    } = useUsersListByCourse(initialCourse)
    const addNewParticipantConfirm = () => {
      isAddNewUserSidebarActive.value = true
      if (items.length > 0) return
      if (((items.value == null || items.value.length == 0) && hasCert.value == false )|| ( (items.value == null || items.value.length == 0)  &&hasExam.value == false)) {
        Swal.fire({
          title: warningTitle,
          text: warningText,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: YES,
          cancelButtonText:  NO,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result) {
            if (result.isConfirmed) {
              return;
            } else {
              isAddNewUserSidebarActive.value = false
              router.push(`/courses/edit/${router.currentRoute.params.courseId}`)
            }
          }
        })
      }
    }

    return {
      addNewParticipantConfirm,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      coursename,
      // Filter
      avatarText,
      items,
      hasExam,
      hasCert,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      fetchCourseSubscribers,
      removeSubscriberFromCourse,
      courseId,
      //i18n
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
