import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import Swal from 'sweetalert2'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { t } = useI18nUtils()

export default function useUsersList(courseid) {
  //console.log("initial course Id " + courseid);
  // Use toast
  const toast = useToast()
  const yes = t('Yes, delete it!')
  const areYouSure = t('Are you sure?')
  const detail = t("You won't be able to revert this!")
  const cancel = t('Cancel')
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'createdAt', class: "cols-1", label: "Registration Date", sortable: true },
    { key: 'membername', class: "col-6", label: "Participant", sortable: true },
    { key: 'detail', class: "col-3", label: "Course", sortable: false },
    { key: 'actions', class: "col-3", label: "Actions", sortable: false },

  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const courseName = ref('')
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('membername')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const courseId = ref(courseid)
  const items = ref(null)
  const hasCert = ref(false)
  const hasExam = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = items.value ? items.value.length : 0
    var from = perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0)
    return {
      from: from,
      to: (from + perPage.value) > totalUsers.value ? totalUsers.value + 1 : from + perPage.value,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    fetchCourseSubscribers()
  }
  const getCourseDetailByGuid = (ctx,callback) =>{
    store.dispatch('apps-participants/fetchCourseDetailByGuid',
      courseId.value
    ).then(response =>{
      const { data } = response.data
      if (data.courseCertified == true) {
        hasCert.value = data.courseCertified
      }
      hasExam.value = data.hasCertificationExam
    })
  }

  const fetchCourseSubscribers = (ctx, callback) => {
    store
      .dispatch('apps-participants/fetchParticipantsByCourse',
        courseId.value
      )
      .then(response => {
        const { data } = response.data
        // callback(data)
        if (data.length > 0) {
          courseName.value = data[0].coursename
          totalUsers.value = data.length
          items.value = data
        }
        else if (data.length == 0) {
          items.value = []
          totalUsers.value = 0
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  fetchCourseSubscribers()
  getCourseDetailByGuid()
  const removeSubscriberFromCourse = (userData) => {
    Swal.fire({
      title: areYouSure,
      text: detail,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: yes,
      cancelButtonText:  cancel,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result) {
        if (result.isConfirmed) {
          store.dispatch('apps-participants/removeParticipantFromCourse', userData).then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: `User removed successfuly`,
                icon: "CheckIcon",
                variant: "success",
              },
            })
            refetchData()
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'User could not be removed ',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
        
      }
    })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status === 0) return 'deleted'
    if (status === 1) return 'active'
    if (status === 2) return 'disabled'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    items,
    hasCert,
    hasExam,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    fetchCourseSubscribers,
    removeSubscriberFromCourse,
    courseId,
  }
}
